<template>
  <transition name="modal">
    <div class="dialog-iso-codes modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container tw-flex tw-flex-col">
          <div class="modal-header tw-flex tw-flex-row tw-justify-between">
            <ui-button
              variant="info"
              class="!tw-bg-transparent"
              @click="handleClose(false)"
            >
              <template #left-icon>
                <ui-ctk-icon
                  class="tw-text-white"
                  name="close"
                  data-test="icon"
                />
              </template>
            </ui-button>
            <h5 class="pl-3 tw-font-normal">
              {{ $t('offer_list.search_engine.geo_selection.title.' + direction) }}
            </h5>
            <ui-button
              variant="primary"
              class="confirm-button"
              @click="handleClose(true)"
            >
              <span class="confirm-button-text">
                {{ $t('offer_list.search_engine.geo_selection.confirm_btn.' + direction) }}
              </span>
              <template #right-icon>
                <ui-ctk-icon
                  name="done"
                  data-test="icon"
                />
              </template>
            </ui-button>
          </div>
          <div class="modal-body tw-flex">
            <div class="tw-flex tw-flex-1 tw-flex-row modal-country-container tw-w-full">
              <select-country
                :direction="direction"
              />
              <section
                class="main-content tw-flex tw-bg-bg-primary"
                role="main"
              >
                <content-countries
                  :selected-country="selectedCountry"
                  :direction="direction"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'
  import _ from 'underscore'

  import useCountryLists from '@/composables/useCountryLists'
  import CountriesList from '@/assets/geo/geo.json'
  import SelectCountry from './_subs/SelectCountry'
  import ContentCountries from './_subs/ContentCountries'

  const {
    getEmptyCountriesList,
    getUnauthorizedSameCountries
  } = useCountryLists()

  export default defineComponent({
    name: 'DialogIsoCodes',
    components: {
      ContentCountries,
      SelectCountry
    },
    props: {
      direction: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        countriesList: CountriesList,
        selectedDivisions: getEmptyCountriesList(),
        selectedCountry: Object.keys(CountriesList)[0]
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ]),
      showDialog () {
        return this.$parent.isoDialogVisible
      },
      isCountryDisabled () {
        const unauthorizedSameCountries = getUnauthorizedSameCountries()

        const {
          pickup_iso_codes: pickup,
          delivery_iso_codes: delivery
        } = this.getCurrentSearch

        const formattedPickup = (pickup || []).map(v => v.split('-')[0])
        const formattedDelivery = (delivery || []).map(v => v.split('-')[0])

        return country => {
          if (this.direction === 'both' && unauthorizedSameCountries.includes(country)) return true

          return unauthorizedSameCountries.includes(country)
            ? (this.direction === 'pickup'
              ? formattedDelivery.includes(country)
              : formattedPickup.includes(country)
            )
            : false
        }
      }
    },
    watch: {
      showDialog (val) {
        if (val) {
          /**
           * Check if the selected country by default is a disabled country.
           * If it's true, then we pick the first country available, that's not disabled.
           */
          if (this.isCountryDisabled(this.selectedCountry)) {
            const availableCountries = Object.keys(CountriesList)
              .filter(country => !this.isCountryDisabled(country))
            this.selectedCountry = availableCountries[0]
          }

          this.selectedDivisions = getEmptyCountriesList()
          const dir = this.direction === 'both' ? 'pickup' : this.direction

          this.$store.state.offers.currentSearch[dir + '_iso_codes'].forEach((objectKey) => {
            if (objectKey.length <= 2) {
              if (this.countriesList[objectKey].length === 0) {
                this.selectedDivisions[objectKey] = true
              } else {
                this.selectedDivisions[objectKey] = _.clone(this.countriesList[objectKey])
              }
            } else {
              this.selectedDivisions[objectKey.split('-')[0]].push(objectKey)
            }
          })
        }
      }
    },
    methods: {
      handleClose (done) {
        if (done) {
          /**
           * Format the divisions list to get a simplified version to
           * push to to Matomo.
           */
          const divisions = []
          Object.keys(this.selectedDivisions)
            .forEach((country) => {
              if (this.selectedDivisions[country] === true) {
                divisions.push(country)
              } else if (this.selectedDivisions[country].length > 0 && (this.selectedDivisions[country].length === CountriesList[country].length)) {
                divisions.push(country)
              } else if (this.selectedDivisions[country] !== true) {
                this.selectedDivisions[country].forEach((division) => {
                  divisions.push(division)
                })
              }
            })

          if (this.$matomo) {
            const eventNames = {
              pickup: 'Confirmed Pickup',
              delivery: 'Confirmed Delivery',
              both: 'Confirmed Pickup And Delivery'
            }

            this.$matomo.trackEvent('Freight Searches', eventNames[this.direction], divisions.join(','))
          }
          this.$emit('selection-done', this.selectedDivisions)
        } else {
          this.$emit('selection-done', null)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  svg {
    margin: 0 auto;

    path.land {
      fill: #1E2C2D;
      stroke: white;
      stroke-width: 0.5;
      transition: fill 0.3s;
      cursor: pointer;

      &:hover {
        fill: #96BF31;
      }
    }
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  .modal-enter {
    transform: translate3d(0, 0, 0);
  }

  .modal-leave-active {
    transform: translate3d(0, 0, 0);
  }

  .modal-country-container {
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }

  .main-content {
    flex: 1;                 /* NEW, Spec - Opera 12.1, Firefox 20+ */
    order: 2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }

  .modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;

    .modal-wrapper {
      height: 100%;

      .modal-container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        background-color: #FFF;
        border-radius: 0;
        transition: all 0.3s ease;

        .modal-header {
          align-items: center;
          border-radius: 0;
          padding: 0 15px;
          background: $info;
          flex: 0 0 60px;

          h5 {
            margin: 0;
            color: #FFF;
          }
        }

        .modal-body {
          padding: 0;
          height: 100%;
          flex: 1;
          overflow: auto;
        }
      }
    }
  }

  .confirm-button {
    .ctk-font {
      display: none;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      padding: 0;

      &-text {
        display: none;
      }

      .ctk-font {
        display: block;
        margin-left: 0;
      }
    }
  }

  .btn-fab {
    box-shadow: none;
    height: 30px;
    width: 30px;
    background: transparent;
    padding: 0;

    i {
      font-size: 30px;
      color: #FFF;
    }
  }

</style>
