<template>
  <div
    id="CtkOffersList"
    class="ctk-offers-list tw-flex tw-flex-col tw-flex-1 tw-h-full"
  >
    <div class="tw-flex tw-overflow-hidden tw-h-full">
      <OffersListContent
        class="md:tw-flex-shrink-0 md:tw-flex-grow-0 tw-w-full"
        @load-more-offers="loadMoreOffers"
      />

      <div
        class="tw-overflow-y-auto tw-w-full tw-absolute md:tw-relative tw-top-0 tw-left-0"
      >
        <OfferDetail
          v-if="getCurrentOffer"
          :offer="getCurrentOffer"
          data-test="detail"
        />
        <OfferUnselected
          v-else
          :title="$t('offers.titles.select_offer')"
          class="tw-hidden md:tw-flex tw-w-full tw-h-full"
          data-test="unselected"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent } from '@vue/composition-api'

  import OffersListContent from './_subs/OffersListContent.vue'
  import OfferDetail from '@/views/Carriers/Offers/components/OfferDetail/index.vue'
  import OfferUnselected from '@/views/Carriers/Offers/components/OfferUnselected/index.vue'

  import useWait from '@/composables/useWait'
  import useStore from '@/composables/useStore'

  export default defineComponent({
    name: 'OffersList',
    components: {
      OffersListContent,
      OfferDetail,
      OfferUnselected
    },
    setup () {
      const wait = useWait()
      const store = useStore()

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

      /**
       * @function loadMoreOffers
       */
      function loadMoreOffers () {
        if (wait.is('loading more offers') || wait.is('loading offers')) return

        if (!(store.value.state.offers.offerPagination >= store.value.state.offers.currentOffersMeta.pagination.page_count || wait.is('loading more offers'))) {
          wait.start('loading more offers')
          store.value.commit('offers/SET_OFFER_PAGINATION', store.value.state.offers.offerPagination + 1)

          store.value.dispatch('offers/loadMoreOffers')
            .catch(() => {})
            .finally(() => {
              wait.end('loading more offers')
            })
        }
      }

      return {
        getCurrentOffer,
        loadMoreOffers
      }
    }
  })
</script>

<style lang="scss">
@media (min-width: 770px) {
  .ctk-offers-list .offers-list-content, .ctk-offers-list .offers-list-subheader {
    width: 420px;
  }
}
.ctk-offers-list .offers-list-content {
  border-right: 1px solid $gray;
}
@media (min-width: 770px) {
  .ctk-offers-list .offers-list-content {
    flex-basis: 420px;
  }
}
</style>
