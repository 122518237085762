<template>
  <transition name="modal">
    <div
      id="CtkModifySearchView"
      ref="view"
      class="tw-h-full tw-w-full tw-bg-bg-primary"
    >
      <ValidationObserver
        ref="observer"
      >
        <div class="header tw-fixed tw-top-0 tw-z-50 tw-w-full tw-h-11 tw-bg-gray-300 tw-flex">
          <p
            v-text="edit
              ? $t('offer_list.search_engine.modification_search')
              : $t('offers.buttons.filter_offers')"
            class="tw-m-0 tw-font-medium tw-text-blue-500 tw-m-auto"
            data-test="title"
          />

          <ui-button
            v-b-tooltip.bottom
            :title="$t('close') | capitalize"
            variant="light"
            class="!tw-absolute tw-top-0 tw-right-2 tw-h-9 tw-w-9 tw-bottom-0 tw-my-auto"
            fab
            data-test="close"
            @click="close(true)"
          >
            <template #left-icon>
              <ui-ctk-icon
                name="close"
                data-test="icon"
              />
            </template>
          </ui-button>
        </div>
        <div class="tw-px-5 tw-pt-14">
          <!-- Search edition form -->
          <template
            v-if="edit"
          >
            <ValidationProvider
              :name="$t('name_of_your_search')"
              :rules="'required|min:1|max:30'"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="search-name"
                  v-model="formData.name"
                  :label="$t('name_of_your_search') | capitalize"
                  :hint="invalid ? errors[0] : null"
                  :error="invalid && validated"
                  name="search-name"
                  class="mb-4"
                  required
                  @input="setCurrentSearchName"
                />
              </template>
            </ValidationProvider>
          </template>

          <div
            v-if="!edit"
            class="tw-flex tw-justify-end"
          >
            <ui-button
              v-show="isInitialSearch"
              variant="light"
              class="animated fadeInRight"
              @click="resetSearch"
            >
              <template #left-icon>
                <ui-ctk-icon
                  name="reset"
                  data-test="icon"
                />
              </template>
              {{ $t('offer_list.search_engine.text_button.reset_search_form') | capitalize }}
            </ui-button>
          </div>

          <search-from
            v-if="show"
            :edition="true"
            class="mb-4"
            @toggle-countries-modal="toggleCountriesModal"
          />

          <!-- Alert enabled -->
          <b-form-checkbox
            v-if="edit"
            v-model="currentSearchAlertEnabled"
            class="mb-4"
            data-test="alert"
          >
            {{ $t(getCurrentSearch.send_by_email
              ? 'saved_searches_section.message.alert_enabled'
              : 'saved_searches_section.message.alert_disabled') }}
          </b-form-checkbox>

          <div class="btn-container">
            <ui-button
              :loading="$wait.is('modifying search')"
              :disabled="$wait.is('modifying search')"
              variant="primary"
              size="lg"
              class="tw-w-full"
              data-test="save"
              @click="modifySearch(getCurrentSearch)"
            >
              {{ $t(edit
                ? 'offer_list.search_engine.modify_my_search'
                : 'save_my_search') | capitalize }}
            </ui-button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import SearchFrom from '../SearchForm/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import { Search } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import { EventBus } from '@/services/EventBus'
  import SearchModel, { maxLengthInCm } from '@/store/modules/offers/models/SearchModel'

  export default defineComponent({
    name: 'ModifySearch',
    components: {
      CtkInputText,
      SearchFrom
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      edit: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        initialeSearch: new SearchModel(),
        formData: {
          name: null
        },
        oldSearch: null
      }
    },
    computed: {
      ...mapGetters('auth', ['getCid']),
      ...mapGetters('offers', [
        'getCurrentSearch',
        'isSameZones'
      ]),
      currentSearchAlertEnabled: {
        get () {
          return this.getCurrentSearch.send_by_email
        },
        set (v) {
          this.setCurrentSearchSendEmail(v)
        }
      },
      /**
       * @function isInitialSearch
       * @returns {boolean}
       */
      isInitialSearch () {
        return JSON.stringify(this.getCurrentSearch) !== JSON.stringify(this.initialeSearch) || this.isSameZones
      }
    },
    watch: {
      show (val) {
        if (val) {
          this.oldSearch = JSON.parse(JSON.stringify(this.getCurrentSearch))
          this.formData.name = this.oldSearch.name
        } else {
          this.oldSearch = null
        }
      }
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentSearch',
        'resetCurrentSearch',
        'updateASearch',
        'setSameZones',
        'refreshSearchCount'
      ]),
      /**
       * @function resetSearch
       */
      resetSearch () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Freight Searches', 'Reset')
        }

        this.setSameZones(false)
        this.resetCurrentSearch()

        EventBus.$emit('get-offers', true)
      },
      setCurrentSearchSendEmail (sendByEmail) {
        this.setCurrentSearch({
          search: {
            ...this.getCurrentSearch,
            send_by_email: sendByEmail
          }
        })
      },
      setCurrentSearchName (name) {
        this.setCurrentSearch({
          search: {
            ...this.getCurrentSearch,
            name
          }
        })
      },
      /**
       * @function close
       * @param {boolean} [ignoreUpdate=false]
       */
      close (ignoreUpdate = false) {
        this.$emit('close')

        if (this.edit && ignoreUpdate && this.oldSearch) {
          this.setCurrentSearch({ search: this.oldSearch })
          this.updateASearch({ search: this.oldSearch })
        }
      },
      async modifySearch (search) {
        if (!this.edit) {
          EventBus.$emit('toggle-save-search-modal', false)
          return
        }

        this.$refs.observer.validate()
          .then(async valid => {
            if (!valid) return

            if (!search.uuid) {
              this.close()
              return false
            }

            this.$wait.start('modifying search')
            const savedSearchPayload = { ...search }
            try {
              // there is no need to store min length as if it searches for all length below max length
              if (typeof search.min_length === 'number' && search.min_length === 0) {
                delete savedSearchPayload.min_length
              }

              // there is no need to store max length as if it searches for all length above min length
              if (typeof search.max_length === 'number' && search.max_length === maxLengthInCm) {
                delete savedSearchPayload.max_length
              }

              await Search.update({ cid: this.getCid, sid: search.uuid }, JSON.stringify(savedSearchPayload))
              if (this.$matomo) {
                this.$matomo.trackEvent('Searches', 'Confirmed Change', search.uuid)
              }
              showToaster(this, this.$t('search_saved'), { type: 'success' })
              this.updateASearch({ search: search })
              this.close()
            } catch (error) {
              showToaster(this, this.$t('error_while_saving_search'), { type: 'error' })
              console.log('error update search', error)
            }
            this.$wait.end('modifying search')
            this.refreshSearchCount(search)
          })
      },
      toggleCountriesModal (direction) {
        this.$emit('toggle-countries-modal', direction)
      }
    }
  })
</script>

<style lang="scss" scoped>

  #CtkModifySearchView {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    z-index: 9;
    padding-bottom: 70px;
    overflow: auto;
  }

  /** Modal animation **/
  .modal-enter {
    transform: translate3d(0, 0, 0);
  }

  .modal-leave-active {
    transform: translate3d(0, 0, 0);
  }

  .modal-enter,
  .modal-leave-active {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

</style>
